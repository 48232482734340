const licensedStates = [
  "AL",
  "AZ",
  "CA",
  "CO",
  "DC",
  "FL",
  "GA",
  "IA",
  "IL",
  "KS",
  "MD",
  "MI",
  "NC",
  "NE",
  "NJ",
  "OR",
  "PA",
  "SC",
  "TN",
  "TX",
  "UT",
  "WA",
  "WI",
  "WY",
];

export default licensedStates;
