import React, { useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./OutputReports.css";
import { Table } from "react-bootstrap";

am4core.useTheme(am4themes_animated);

export function OutputReports(props) {
  const [chart_vpr, setChartVpr] = useState(null);
  const [chart_cdr, setChartCdr] = useState(null);
  const [chart_rec, setChartRec] = useState(null);
  const outputData = props.outputData;
  const isLC = props.isLC;
  const [leftColWidth, setLeftColWidth] = useState("col-xl-6");
  const boldKeys = [
    "Interest Rate (%)",
    "Broker Comp. (%)",
    "HELOC Margin (%)",
  ];
  const singleCellKeys = ["Qualifying Credit Score", "User", "State"];
  useEffect(() => {
    if (
      outputData.cum_vpr_graph &&
      outputData.cum_cdr_graph &&
      outputData.cum_recovery_graph
    ) {
      renderVprGraph();
      renderCdrGraph();
      renderRecGraph();
    } else {
      setLeftColWidth("col-md-12");
    }
  }, []);

  function renderVprGraph() {
    let chart_vpr = am4core.create("chart_vpr", am4charts.XYChart);

    chart_vpr.paddingRight = 20;

    let data = [];
    for (let i = 0; i < outputData.cum_vpr_graph.length; i++) {
      // visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      data.push({
        loan_age_month: outputData.cum_vpr_graph[i].loan_age_month,
        cum_vpr: outputData.cum_vpr_graph[i]["cum_vpr_%"] * 100,
      });
    }
    chart_vpr.data = data;

    let categoryAxis = chart_vpr.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "loan_age_month";
    categoryAxis.title.text = "Loan Age (months)";

    let valueAxis = chart_vpr.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Cumulative VPR (%)";

    let series = chart_vpr.series.push(new am4charts.LineSeries());
    series.dataFields.categoryX = "loan_age_month";
    series.dataFields.valueY = "cum_vpr";
    series.name = "Cumulative VPR (%)";
    series.tooltipText = "{name}: {valueY}%";
    series.strokeWidth = 2;

    var title = chart_vpr.titles.create();
    title.text = "Cumulative VPR (%)";
    title.fontSize = 18;
    title.marginBottom = 15;

    chart_vpr.cursor = new am4charts.XYCursor();

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart_vpr.scrollbarX = scrollbarX;

    setChartVpr(chart_vpr);
  }

  function renderCdrGraph() {
    let chart_cdr = am4core.create("chart_cdr", am4charts.XYChart);

    chart_cdr.paddingRight = 20;

    let data = [];
    for (let i = 0; i < outputData.cum_cdr_graph.length; i++) {
      // visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      data.push({
        loan_age_month: outputData.cum_cdr_graph[i].loan_age_month,
        cum_cdr: outputData.cum_cdr_graph[i]["cum_cdr_%"] * 100,
      });
    }
    chart_cdr.data = data;

    let categoryAxis = chart_cdr.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "loan_age_month";
    categoryAxis.title.text = "Loan Age (months)";

    let valueAxis = chart_cdr.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Cumulative CDR (%)";

    let series = chart_cdr.series.push(new am4charts.LineSeries());
    series.dataFields.categoryX = "loan_age_month";
    series.dataFields.valueY = "cum_cdr";
    series.name = "Cumulative CDR (%)";
    series.tooltipText = "{name}: {valueY}%";
    series.stroke = am4core.color("#ED7D31");
    series.strokeWidth = 2;

    var title = chart_cdr.titles.create();
    title.text = "Cumulative CDR (%)";
    title.fontSize = 18;
    title.marginBottom = 15;

    chart_cdr.cursor = new am4charts.XYCursor();

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart_cdr.scrollbarX = scrollbarX;

    setChartCdr(chart_cdr);
  }

  function renderRecGraph() {
    let chart_rec = am4core.create("chart_rec", am4charts.XYChart);

    chart_rec.paddingRight = 20;

    let data = [];
    for (let i = 0; i < outputData.cum_recovery_graph.length; i++) {
      // visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      data.push({
        loan_age_month: outputData.cum_recovery_graph[i].loan_age_month,
        cum_rec: outputData.cum_recovery_graph[i]["cum_rec_%"] * 100,
      });
    }
    chart_rec.data = data;

    let categoryAxis = chart_rec.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "loan_age_month";
    categoryAxis.title.text = "Loan Age (months)";

    let valueAxis = chart_rec.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Cumulative Recovery (%)";

    let series = chart_rec.series.push(new am4charts.LineSeries());
    series.dataFields.categoryX = "loan_age_month";
    series.dataFields.valueY = "cum_rec";
    series.name = "Cumulative Recovery (%)";
    series.tooltipText = "{name}: {valueY}%";
    series.stroke = am4core.color("#70AD47");
    series.strokeWidth = 2;

    var title = chart_rec.titles.create();
    title.text = "Cumulative Recovery (%)";
    title.fontSize = 18;
    title.marginBottom = 15;

    chart_rec.cursor = new am4charts.XYCursor();

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart_rec.scrollbarX = scrollbarX;

    setChartRec(chart_rec);
  }
  const renderTr = (data, item) => {
    return (
      <tr key={item}>
        <td
          className="text-primary"
          style={{
            fontWeight: boldKeys.includes(item) ? "bolder" : "normal",
            width: "18%",
          }}
        >
          {item}
        </td>
        {data.map((field) => {
          return (
            <td
              style={{
                fontWeight: boldKeys.includes(item) ? "bolder" : "normal",
              }}
            >
              {field}
            </td>
          );
        })}
      </tr>
    );
  };
  const fillBlankTr = () => {
    return (
      <tr>
        <td colSpan={4} style={{ height: 24 }} />
        <td style={{ height: 24, background: "rgb(255, 184, 0)" }} />
        <td colSpan={3} style={{ height: 24 }} />
      </tr>
    );
  };
  const renderOptionsTable = (optionsData, label, isHeloc = false) => {
    const inc = isHeloc ? 0 : 2;
    return (
      <>
        <h5 className="card-title">{label} Options</h5>
        <Table bordered className="table table-sm lo-table">
          <thead>
            <tr>
              <th style={{ width: "18%" }}></th>
              {optionsData.columns.map((item) => {
                return <th key={item}>{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {Object.keys(optionsData.data)
              .slice(0, 12 - inc)
              .map((item) => {
                return renderTr(optionsData.data[item], item);
              })}
            {fillBlankTr()}
            {Object.keys(optionsData?.data)
              .slice(13 - inc)
              .map((item) => {
                return renderTr(optionsData.data[item], item);
              })}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <div className="position-relative mt-3" style={{ fontSize: 14 }}>
      <div className="mb-3">
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="card shadow p-3">
              <h6>Loan Outputs</h6>
              <Table striped bordered hover className="table-sm output-report">
                <tbody>
                  {outputData.result.slice(0, 6).map((item) => {
                    return (
                      <tr key={item.name}>
                        <td className="text-primary">{item.name}</td>
                        <td colSpan={2} style={{ fontWeight: "bold" }}>
                          {item.value}
                        </td>
                      </tr>
                    );
                  })}
                  <tr style={{ height: 24 }}>
                    <td colSpan={3}></td>
                  </tr>
                  <tr>
                    <td className="text-primary">Loan Type</td>
                    <td style={{ fontWeight: "bold" }}>HELOAN</td>
                    <td style={{ fontWeight: "bold" }}>HELOC</td>
                  </tr>
                  {outputData?.result?.slice(6).map((item) => {
                    if (singleCellKeys.includes(item.name)) {
                      return (
                        <tr>
                          <td className="text-primary">{item.name}</td>
                          <td colSpan={2} style={{ fontWeight: "bold" }}>
                            {item.value}
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <tr key={item.name}>
                        <td className="text-primary">{item.name}</td>
                        <td style={{ fontWeight: "bold" }}>{item.value}</td>
                        <td style={{ fontWeight: "bold" }}>{item.value2}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="card shadow p-3">
              <h6>Loan Inputs</h6>
              <Table bordered className="table-sm output-report">
                <tbody>
                  {outputData.input.map((item) => {
                    return (
                      <tr key={item.name}>
                        <td className="text-primary">{item.name}</td>
                        <td>{item.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="col-md-12 mb-3">
            <div className="card shadow">
              <div className="card-body">
                {outputData?.loanOptions &&
                  renderOptionsTable(outputData?.loanOptions, "HELOAN")}
                <br />
                {outputData?.loanOptions2 &&
                  renderOptionsTable(outputData?.loanOptions2, "HELOC", true)}
                <p className="mt-3">
                  Please indicate the Loan Option that you would like when you{" "}
                  <a href="https://buttonfinance.encompasstpoconnect.com/">
                    submit your loan
                  </a>
                  . Button Finance might use BPC instead of LPC due to state and
                  federal regulations. HELOC Floor is 2% below Note Rate.
                </p>
              </div>
            </div>
          </div>
          <div
            hidden={
              !(
                outputData.cum_vpr_graph &&
                outputData.cum_cdr_graph &&
                outputData.cum_recovery_graph
              )
            }
            className="col-xl-12"
          >
            <div
              className="card shadow p-3 mb-3"
              id="chart_vpr"
              style={{ width: "100%", height: "400px" }}
            ></div>
            <div
              className="card shadow p-3 mb-3"
              id="chart_cdr"
              style={{ width: "100%", height: "400px" }}
            ></div>
            <div
              className="card shadow p-3 mb-3"
              id="chart_rec"
              style={{ width: "100%", height: "400px" }}
            ></div>
          </div>
        </div>
      </div>

      {/* <div className='text-end'>Run ID: {outputData.runid}</div> */}
    </div>
  );
}
